<template>
	<div class="animated fadeIn">
		<b-card title="Blogs" sub-title="Ayun! events and blogs">
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
			<b-card-text class="mt-4">
				<b-row>
					<b-col sm="6" md="3" class="mb-2">
						<b-dropdown text=" Select Actions " variant="dark">
							<b-dropdown-item @click="eventBus.$emit('showAddBlogDialog')">
								<i class="fa fa-plus"></i>&nbsp; Add New
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="3" offset-md="6" class="mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table :items="items" :fields="fields" :responsive="true" selected-variant="primary"
					:current-page="currentPage" :per-page="perPage" show-empty>
					<template v-slot:cell(dateCreated)="row">
						<div class="text-nowrap">
							{{ row.item.dateCreated | toDateTimeString }}
						</div>
					</template>
					<template v-slot:cell(dateUpdated)="row">
						<div class="text-nowrap">
							{{ row.item.dateUpdated | toDateTimeString }}
						</div>
					</template>
					<template v-slot:cell(isDeleted)="row">
						<div class="text-nowrap">
							<b-badge class="p-1" :variant="`${row.item.isDeleted ? 'danger' : 'success'}`">
								{{ row.item.isDeleted ? 'YES' : 'NO' }}
							</b-badge>
						</div>
					</template>
					<template v-slot:cell(actions)="row">
						<div class="text-nowrap">
							<b-button size="sm" variant="info" class="mr-1"
								@click="eventBus.$emit('showUpdateBlogDialog', row.item)">
								<i class="fa fa-pencil"></i>
							</b-button>
							<b-button size="sm" variant="danger" class="mr-1"
								@click="eventBus.$emit('showDeleteBlogDialog', row.item)">
								<i class="fa fa-trash"></i>
							</b-button>
							<b-button v-if="row.item.isDeleted" size="sm" variant="success"
								v-b-tooltip.hover.top="'Undo Delete'"
								@click="eventBus.$emit('showUndoDeleteBlogDialog', row.item.id)">
								<i class="fa fa-undo"></i>
							</b-button>
						</div>
					</template>
				</b-table>
			</b-card-text>

			<template v-slot:footer>
				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ items.length }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="items.length" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</template>

			<!-- Modals -->
			<AddBlog />
			<UpdateBlog />
			<DeleteBlog />
			<UndoDeleteBlog />
		</b-card>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import EventBus from '@/shared/event-bus';

// Modals
import AddBlog from './blog/AddBlog';
import UpdateBlog from './blog/UpdateBlog';
import DeleteBlog from './blog/DeleteBlog';
import UndoDeleteBlog from './blog/UndoDeleteBlog';

// DAO
import blogsDAO from '../../database/blogs';

export default {
	name: 'blogs',
	components: {
		AddBlog,
		UpdateBlog,
		DeleteBlog,
		UndoDeleteBlog,
		Loading,
	},
	data() {
		return {
			eventBus: EventBus,
			isLoading: false,
			items: [],
			fields: [
				{ key: 'title', label: 'Title' },
				{ key: 'dateCreated', label: 'Date Created' },
				{ key: 'dateUpdated', label: 'Date Updated' },
				{ key: 'isDeleted', label: 'Is Deleted?' },
				{ key: 'actions', label: 'Actions' },
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filterBy: {
				category: null,
			},
		};
	},
	mounted() {
		this.retrieveBlogs();

		EventBus.$on('refreshBlogsList', () => this.retrieveBlogs());
	},
	methods: {
		async retrieveBlogs() {
			// show loading indicator
			this.isLoading = true;

			try {
				const blogsObj = await blogsDAO.retrieve(this.filterBy);
				this.items = Object.values(blogsObj);
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('refreshBlogsList');
	},
};
</script>